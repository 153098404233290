<template>
  <!-- 礼物明细页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <!-- 礼物名称 -->
                <!-- <a-col :md="5" :sm="24">
                  <a-form-item label="礼物名称">
                    <a-input
                      v-model="queryParam.name"
                      allow-clear
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col> -->
                <!-- 赠送人 -->
                <a-col :md="5" :sm="24">
                  <a-form-item label="关键词">
                    <a-input v-model="queryParam.keyword" allow-clear placeholder="关键词搜索" />
                  </a-form-item>
                </a-col>
                <!-- 搜索按钮 -->
                <a-col :md="8" :sm="24">
                  <a-button type="primary" @click="initTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                  <a-button style="margin-left: 8px" type="primary" @click="exportListData">导出</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
          >
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 25px; display: flex; flex-direction: row-reverse; justify-content: space-between">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            ></a-pagination>
            <div>
              <span style="font-weight: bold">总计：</span>
              <span style="font-weight: bold"
                >数量&nbsp; <span>{{ num }}</span></span
              >
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span style="font-weight: bold"
                >产币&nbsp; <span>{{ coin }}</span></span
              >
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { liveGiftDetail, liveGiftCon } from '@/api/modular/mallLiving/liveRoomAdmin'

const columns = [
  {
    title: '礼物名称',
    align: 'center',
    dataIndex: 'giftName',
  },
  {
    title: '赠送人',
    align: 'center',
    dataIndex: 'nickName',
  },
  {
    title: '赠送人ID号',
    align: 'center',
    dataIndex: 'idNo',
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    align: 'center',
  },
  {
    title: '数量',
    dataIndex: 'num',
    align: 'center',
  },
  {
    title: '产币',
    dataIndex: 'coin',
    align: 'center',
  },
  {
    title: '赠送时间',
    dataIndex: 'createTime',
    align: 'center',
  },
]

export default {
  data() {
    return {
      liveId: '', //带过来的直播间id

      columns,
      data: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      loading: false,
      queryParam: {
        isExport: 1,
      },
      num: '0', //总计数量
      coin: '0', //总计产币
    }
  },
  mounted() {
    this.liveId = this.$route.query.liveId
    this.initTableData()
  },
  methods: {
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    exportListData() {
      let params = this.queryParam
      params.isExport = 2
      params.liveId = this.liveId
      this.axios
        .post(`/sendGift/page`, params, {
          responseType: 'blob', //服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'，默认是'json'
        })
        .then((res) => {
          if (!res) return
          const blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          }) // 构造一个blob对象来处理数据，并设置文件类型

          const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载链接
          a.download = '直播礼物明细_' + new Date().getTime() + '.xlsx' //指定下载文件名
          a.click() //触发下载
          URL.revokeObjectURL(a.href) //释放URL对象
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getList() {
      this.loading = true
      this.queryParam.isExport = 1
      var params = {
        page: this.page,
        queryParam: {
          liveId: this.liveId,
          ...this.queryParam,
        },
      }
      liveGiftDetail(params)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })

      liveGiftCon(params.queryParam)
        .then((res) => {
          if (res.success) {
            this.num = res.data.num
            this.coin = res.data.coin
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}
.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}
.right-content {
  box-sizing: border-box;
  padding: 10px;
}
.icon {
  font-size: 20px;
  margin: 0 5px;
}
img {
  width: 20px;
  height: 20px;
}
</style>
